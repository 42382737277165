import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [{
        path: '/hub/:token',
        name: 'Hub',
        component: () =>
            import ('../views/Hub.vue'),
    }, //自动分发器
    {
        path: '/error',
        name: 'Error',
        component: () =>
            import ('../views/Error.vue'),
    },
    {
        path: '/return',
        name: 'Return',
        component: () =>
            import ('../views/Return.vue'),
    },
    {
        path: '/btcreturn',
        name: 'BtcReturn',
        component: () =>
            import ('../views/BtcReturn.vue'),
    },
    {
        path: '/cashier',
        name: 'Cashier',
        component: () =>
            import ('../views/Cashier.vue'), //收银台（该地址无意义）
        children: [{
                path: '/cashier/payway',
                name: 'CashierPayway',
                component:
                    () =>
                    import ('../views/channel/Payway.vue'),
            },
            {
                path: '/cashier/stripepay',
                name: 'CashierStripepay',
                component:
                    () =>
                    import ('../views/channel/Stripepay.vue'),
            },
            {
                path: '/cashier/sandboxpay',
                name: 'CashierSandboxpay',
                component:
                    () =>
                    import ('../views/channel/Sandboxpay.vue'),
            },
            {
                path: '/cashier/ecpay',
                name: 'CashierEcpay',
                component:
                    () =>
                    import ('../views/channel/Ecpay.vue'),
            },
            {
                path: '/cashier/zellepay',
                name: 'CashierZellepay',
                component:
                    () =>
                    import ('../views/channel/Zellepay.vue'),
            },
            {
                path: '/cashier/ecnewpay',
                name: 'CashierEcnewpay',
                component:
                    () =>
                    import ('../views/channel/Ecnewpay.vue'),
            },
            {
                path: '/cashier/doipay',
                name: 'CashierDoipay',
                component:
                    () =>
                    import ('../views/channel/Doipay.vue'),
            },
            {
                path: '/cashier/linkedpay',
                name: 'CashierLinkedpay',
                component:
                    () =>
                    import ('../views/channel/Linkedpay.vue'),
            },
            {
                path: '/cashier/gemapay',
                name: 'CashierGemapay',
                component:
                    () =>
                    import ('../views/channel/Gemapay.vue'),
            },
            {
                path: '/cashier/codapay',
                name: 'CashierCodapay',
                component:
                    () =>
                    import ('../views/channel/Codapay.vue'),
            },
        ],
    },
    {
        path: '/cashier/btcserver',
        name: 'CashierBtcserver',
        component: () =>
            import ('../views/channel/Btcserver.vue'), //收银台（该地址无意义） 
    },
    {
        path: '/help',
        name: 'btcHelp',
        component: () =>
            import ('../views/help/Index.vue'), 
    },
    {
        // Catch all routes ("*") must now be defined using a param with a custom regexp
        path: "/:pathMatch(.*)",
        component: () =>
            import ('../views/404.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router